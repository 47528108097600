import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{staticClass:"app_bar",style:(("position: " + (_vm.$route.name.includes('Banker') ? 'relative' : 'absolute'))),attrs:{"color":_vm.background_color,"flat":"","height":_vm.$route.name.includes('Banker') &&
    !_vm.$route.name.includes('BankerRegister') &&
    _vm.gs.isMobile()
      ? '160'
      : '85'}},[_c(VContainer,{staticClass:"px-0 text-right mr-0 v-container-flex",class:{
      'v-container-flex-column':
        _vm.$route.name.includes('Banker') &&
        !_vm.$route.name.includes('BankerRegister'),
    }},[(
        (_vm.$route.name.includes('Banker') && _vm.$route.name != 'BankerRegister') ||
        (_vm.$route.name == 'BankerRegister' && !_vm.gs.isMobile())
      )?_c('div',[_c('div',[(_vm.partnerLogo)?_c('img',{attrs:{"src":_vm.partnerLogo}}):_c('img',{staticClass:"mr-2",staticStyle:{"width":"150px","max-height":"70px"},attrs:{"src":require("@/assets/dealboard-logo-white.svg"),"alt":"dealboard logo"}})])]):(_vm.$route.name == 'BankerRegister' && _vm.gs.isMobile())?_c('div',{staticStyle:{"margin":"0px -15px 0 -31px"}},[_c('h3',{staticClass:"my-auto ml-6",staticStyle:{"color":"var(--white)","font-weight":"bold","font-size":"20px","line-height":"25px","letter-spacing":"0.15px"}},[_vm._v(" "+_vm._s(_vm.$t("alpha_register_step_count", { step: _vm.step, totalSteps: _vm.totalSteps, }))+" ")]),_c(VProgressLinear,{staticClass:"dxa-progress-bar",staticStyle:{"position":"absolute","bottom":"0px"},attrs:{"color":"#1a7dff","height":"4","value":(_vm.step * 100) / 2}})],1):_vm._e(),_c(VSpacer),_c(VMenu,{staticStyle:{"z-index":"1000"},attrs:{"bottom":"","left":"","min-width":"200","offset-y":"","origin":"top right","transition":"scale-transition","disabled":_vm.languageMenuDisabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var attrs = ref.attrs;
      var on = ref.on;
return [(_vm.selectedLanguage != null)?_c(VCard,_vm._g(_vm._b({staticClass:"btn_lang d-flex",staticStyle:{"width":"125px"},attrs:{"height":"30px","min-width":"100px"}},'v-card',attrs,false),on),[_c(VImg,{attrs:{"max-width":"23px","height":"23px","src":_vm.selectedLanguageAvatar()}}),(_vm.selectedLanguage.value == 'pt')?_c('p',{staticClass:"p_br"},[_vm._v(" PORTUGUÊS/BR ")]):_c('p',{staticClass:"p_en"},[_vm._v("ENGLISH")])],1):_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-account-voice")])],1)]}}])},[_c(VList,{attrs:{"tile":false,"flat":"","nav":"","color":"dark"}},_vm._l((_vm.langs),function(l,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.changeLanguage(l)}}},[_c(VListItemTitle,{staticStyle:{"line-height":"1.4"},domProps:{"textContent":_vm._s(l.text)}})],1)}),1)],1),_vm._l((_vm.items),function(item,i){return _c(VBtn,{key:i,staticClass:"ml-2",attrs:{"min-height":"48","min-width":"40","text":""},on:{"click":function($event){return _vm.redirect(item.to)}}},[_c(VIcon,{attrs:{"left":_vm.$vuetify.breakpoint.mdAndUp,"size":"20"},domProps:{"textContent":_vm._s(item.icon)}}),_c('span',{staticClass:"hidden-sm-and-down",domProps:{"textContent":_vm._s(item.text)}})],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }