<template>
  <v-main id="pages">
    <!-- <img
      v-if="imgBackground && !gs.isMobile()"
      :src="imgBackground"
      style="
        position: absolute;
        object-fit: cover;
        max-height: -webkit-fill-available;
      "
      :class="{ 'opacity-on': check_opacity }"
      class="mb-5"
    /> -->
    <!-- <div
      v-else
      :style="`height: 100%; width: 100%; background: ${background_color}; position: absolute`"
    ></div> -->
    <v-responsive min-height="100vh">
      <router-view />
    </v-responsive>
  </v-main>
</template>
<style>
.opacity-on {
  opacity: 15%;
}
</style>
<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
export default {
  name: "PagesCoreView",

  data: () => ({
    gs: new GeneralServices(),
    srcs: {
      "/pages/login": "login.jpg",
      "/pages/register": "register.jpg",
    },
    customBackground: null,
  }),

  computed: {
    check_opacity() {
      return (
        this.$route.name == "WelcomePage" ||
        this.$route.name == "AlphaRegisterClient" ||
        this.$route.name == "AlphaRegisterClientDXA" ||
        this.$route.name == "AlphaUserRegisterClient"
      );
    },
    background_color() {
      const documentStyle = window.getComputedStyle(document.documentElement);

      if (
        this.$route.name == "BankerRegister" ||
        this.$route.name == "BankerOnboarding"
      ) {
        const documentWhiteColor = documentStyle.getPropertyValue("--white");

        return documentWhiteColor;
      } else {
        const documentDarkColor = documentStyle.getPropertyValue("--dark");

        return documentDarkColor;
      }
    },
    imgBackground() {
      if (!this.$route.name.includes("Banker")) {
        // return require("../../../../assets/DXA_Background_Login.png");
        return require("../../../../assets/DXA_Background_Login_V2.svg");
      }
      return null;
    },
    src() {
      return this.srcs[this.$route.path];
    },
    styles() {
      const paddingTop = this.$vuetify.breakpoint.mdAndUp ? 175 : 100;
      const paddingBottom = this.$vuetify.breakpoint.mdAndUp ? 0 : 0;
      return {
        padding: `${paddingTop}px 20px ${paddingBottom}px 20px`,
      };
    },
  },
};
</script>
