<template>
  <v-app-bar
    :color="background_color"
    flat
    :height="
      $route.name.includes('Banker') &&
      !$route.name.includes('BankerRegister') &&
      gs.isMobile()
        ? '160'
        : '85'
    "
    :style="`position: ${
      $route.name.includes('Banker') ? 'relative' : 'absolute'
    }`"
    class="app_bar"
  >
    <v-container
      class="px-0 text-right mr-0 v-container-flex"
      :class="{
        'v-container-flex-column':
          $route.name.includes('Banker') &&
          !$route.name.includes('BankerRegister'),
      }"
    >
      <!-- <v-toolbar-title
        class="font-weight-light hidden-xs-only"
        v-text="title"
      /> -->

      <!-- only show when is banker register -->
      <div
        v-if="
          ($route.name.includes('Banker') && $route.name != 'BankerRegister') ||
          ($route.name == 'BankerRegister' && !gs.isMobile())
        "
      >
        <div>
          <img v-if="partnerLogo" :src="partnerLogo" />
          <img
            v-else
            style="width: 150px; max-height: 70px"
            src="@/assets/dealboard-logo-white.svg"
            class="mr-2"
            alt="dealboard logo"
          />
        </div>
      </div>
      <div
        v-else-if="$route.name == 'BankerRegister' && gs.isMobile()"
        style="margin: 0px -15px 0 -31px"
      >
        <h3
          class="my-auto ml-6"
          style="
            color: var(--white);
            font-weight: bold;
            font-size: 20px;
            line-height: 25px;
            letter-spacing: 0.15px;
          "
        >
          {{
            $t("alpha_register_step_count", {
              step: step,
              totalSteps: totalSteps,
            })
          }}
        </h3>

        <v-progress-linear
          color="#1a7dff"
          height="4"
          style="position: absolute; bottom: 0px"
          class="dxa-progress-bar"
          :value="(step * 100) / 2"
        ></v-progress-linear>
      </div>
      <v-spacer />

      <v-menu
        style="z-index: 1000"
        bottom
        left
        min-width="200"
        offset-y
        origin="top right"
        transition="scale-transition"
        :disabled="languageMenuDisabled"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-card
            class="btn_lang d-flex"
            v-if="selectedLanguage != null"
            style="width: 125px"
            height="30px"
            min-width="100px"
            v-bind="attrs"
            v-on="on"
          >
            <v-img
              max-width="23px"
              height="23px"
              :src="selectedLanguageAvatar()"
            ></v-img>
            <p class="p_br" v-if="selectedLanguage.value == 'pt'">
              PORTUGUÊS/BR
            </p>
            <p class="p_en" v-else>ENGLISH</p>
          </v-card>
          <v-btn
            v-else
            class="ml-2"
            min-width="0"
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-account-voice</v-icon>
          </v-btn>
        </template>

        <v-list :tile="false" flat nav color="dark">
          <v-list-item
            v-for="(l, i) in langs"
            :key="i"
            @click="changeLanguage(l)"
          >
            <v-list-item-title v-text="l.text" style="line-height: 1.4" />
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        v-for="(item, i) in items"
        :key="i"
        @click="redirect(item.to)"
        min-height="48"
        min-width="40"
        class="ml-2"
        text
      >
        <v-icon
          :left="$vuetify.breakpoint.mdAndUp"
          size="20"
          v-text="item.icon"
        />

        <span class="hidden-sm-and-down" v-text="item.text" />
      </v-btn>
    </v-container>
  </v-app-bar>
</template>
<style scoped>
.btn_lang {
  text-align: center;
  justify-content: Center;
  align-items: center;
  background-color: var(--white);
}
p {
  margin: 3px;
}
.p_en {
  color: var(--secondary);
}
.p_br {
  color: var(--primary);
  font-size: 12px;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import GeneralServices from "@/services/GeneralServices";
import PartnerService from "@/services/PartnerService";

export default {
  name: "PagesCoreAppBar",

  data: () => ({
    gs: new GeneralServices(),
    ps: new PartnerService(),
    titles: {
      "/auth": "Login Page",
      "/auth/register": "Register Page",
    },
    langs: [
      {
        text: "English",
        value: "en",
      },
      {
        text: "Português",
        value: "pt",
      },
    ],
    selectedLanguage: null,
    isFromPartner: false,
    languageMenuDisabled: false,
    partnerLogo: null,
  }),

  mounted() {
    this.langs.forEach((element) => {
      if (this.$i18n.locale == element.value) {
        this.selectedLanguage = element;
      }
    });
  },

  async created() {
    let partner_name = null;

    if (this.$route.query.partner) {
      partner_name = this.$route.query.partner;
    }

    window.addEventListener(
      "disableLanguageMenu",
      () => (this.languageMenuDisabled = true)
    );

    window.addEventListener(
      "enableLanguageMenu",
      () => (this.languageMenuDisabled = false)
    );

    // garantindo que a chamada só vai ser feita, quando for cadastro banker e tiver um parceiro na url
    // assim não chamamos a request em todos os lugares que usamos o AppBar.vue
    if (partner_name && this.$route.name.includes("Banker")) {
      try {
        let partner = await this.ps.get_partner_configs(partner_name);
        let parsedPartner = JSON.parse(partner);
        this.partnerLogo = parsedPartner.CompanyLogoPath;
      } catch (err) {
        return err;
      }
    }
  },

  methods: {
    selectedLanguageAvatar: function () {
      if (this.selectedLanguage.value.toUpperCase() == "PT") {
        return require("../../../../assets/Brasil-Icon.png");
      } else {
        return require("../../../../assets/UK-Icon.png");
      }
    },
    changeLanguage: function (l) {
      this.$i18n.locale = l.value;
      this.$store.commit("SET_LANGUAGE", l.value);
      this.selectedLanguage = l;
    },
    redirect: function (path) {
      if (!path.includes("https")) {
        if (Object.keys(this.$route.query).length > 0) {
          this.$router.replace({
            path:
              path +
              `?partner=true&company_name=${this.$route.query.company_name}&partner_name=${this.$route.query.partner_name}`,
          });
        } else {
          this.$router.replace({
            path: path,
          });
        }
      } else {
        window.location.href = path;
      }
    },
  },
  watch: {
    lang(newValue) {
      let filteredSelectedLanguage = this.langs.filter((stateLang) => {
        return stateLang.value === newValue;
      });
      this.selectedLanguage = filteredSelectedLanguage[0];
    },
  },
  computed: {
    ...mapGetters({
      configs: "get_partner",
      step: "get_step",
      totalSteps: "get_total_steps",
    }),
    ...mapState(["lang"]),
    background_color() {
      if (this.$route.name.includes("Banker")) {
        const documentStyle = window.getComputedStyle(document.documentElement);
        let documentDarkColor = documentStyle.getPropertyValue("--dark");

        return documentDarkColor;
      }
      return "transparent";
    },
    title() {
      return this.titles[this.$route.path];
    },
    items() {
      return [
        // {
        //   icon: "mdi-account-multiple-plus",
        //   text: this.$t("register"),
        //   to: "/auth/register-client",
        // },
        // {
        //   icon: "mdi-fingerprint",
        //   text: this.$t("login"),
        //   to: "/auth",
        // },
      ];
    },
  },
};
</script>
<style scoped>
.btn_lang {
  text-align: center;
  justify-content: Center;
  align-items: center;
  background-color: var(--white);
  margin: 30px 15px;
}
p {
  margin: 3px;
}
.p_en {
  color: var(--secondary);
}
.p_br {
  color: var(--primary);
  font-size: 12px;
}

.v-container-flex {
  display: flex;
  align-items: center;
}

.box-logo-partner {
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

@media only screen and (max-width: 769px) {
  .v-container-flex-column {
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1264px) {
  .container {
    max-width: 100%;
  }
}
</style>
