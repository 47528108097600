<template>
  <v-app>
    <pages-core-app-bar v-if="appBar" />

    <pages-core-view />

    <!-- <pages-core-footer /> -->
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import PagesCoreAppBar from "./components/core/AppBar";
import PagesCoreView from "./components/core/View";
// import PagesCoreFooter from "./components/core/Footer";

export default {
  name: "PagesIndex",

  components: {
    "pages-core-app-bar": PagesCoreAppBar,
    "pages-core-view": PagesCoreView,
    // "pages-core-footer": PagesCoreFooter,
  },

  created() {
    this.$vuetify.theme.dark = true;
  },
  computed: {
    ...mapState(["appBar"]),
  },
  beforeDestroy() {
    this.$vuetify.theme.dark = false;
  },
};
</script>
