import ApiService from "./ApiService";
const apiService = new ApiService();
export default class PartnerService {
  constructor() {}
  async get_partner_configs(partner_name) {
    let configs = null;
    await apiService
      .getRequest(`partner/get/${partner_name}`)
      .then((response) => {
        configs = response;
      })
      .catch((error) => {
        return error;
      });
    return configs;
  }
  async validate_partner(partnerName) {
    var partnerId = null;
    await apiService
      .getRequest(`partner/exists?PartnerName=${partnerName}`)
      .then((resp) => {
        partnerId = resp.content.id;
      });
    return partnerId;
  }
}
